<template>
  <div>
    <v-divider></v-divider>
    <v-row v-if="!$props.facilityID" v-show="!isOPS" class="py-0">
      <v-col class="py-0 pr-0">
        <HbPropertySelector
            id="facility"
            :items="facilityList"
            :disabled="isLoading($options.name)"
            :loading="isLoading($options.name)"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-scope="gate"
            data-vv-name="facility"
            data-vv-as="Property"
            :error="errors.has('gate.facility')"
            @change="validateFacilitySelected()"
        />
      </v-col>
    </v-row>

    <v-row  class="my-0" >
      <v-col v-if="$props.facilityID || property_id">
        <access-control-configuration v-if="isAccessControlConfigurationSelected" :facilityID="facilityID" :updatePageMenu="updatePageMenu" :showAccessHoursSettings="showAccessHoursSettings" :property_id="property_id"/>
        <sync-reports v-if="!isAccessControlConfigurationSelected" :property_id="property_id"/>
      </v-col>
      <v-col v-else>
        <hb-empty-state
            message="Please select a Property to continue."
        >
        </hb-empty-state>
      </v-col>
      <v-col style="max-width: 250px;">
        <div  >
          <v-container class="ma-0 pa-0" style="height: 75vh;" fluid>
            <v-row class="ma-0 pa-0">
              <v-col :cols="showTertiary ? 0 : 0" class="ma-0 pa-0" style="height: 75vh;" v-if="showTertiary">
                <hb-page-menu :width="'250px'" v-model="showTertiary">
                  <v-list dense>
                    <v-list-item-group v-model="selection" mandatory>
                      <v-list-item v-for="item in menuItems" exact :key="item">
                        {{item}}
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </hb-page-menu>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>


  </div>
</template>

<script type="text/babel">
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import AccessControlConfiguration from "./AccessControlConfiguration.vue";
import {mapGetters, mapActions} from 'vuex';
import api from "../../../assets/api";
import SyncReports from "./SyncReports.vue";

export default {
  name: "AccessControl",
  components: {SyncReports, AccessControlConfiguration},
  mixins: [notificationMixin],
  data() {
    return {
      selection: 0,
      property_id: "",
      showTertiary: true,
      syncReportFlag: false,
      menuItems: ['Access Control Configuration'],
    };
  },
  props: ["facilityID","isOPS", "showAccessHoursSettings"],
  computed: {
    ...mapGetters({
      onboardingData: "onboardingTabsStore/getOnboardingData",
      facilityList: 'propertiesStore/filtered',
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isAccessControlConfigurationSelected() {
      return this.selection === 0;
    }
  },
  mounted() {
    this.fetchGateVendorsList();
    if (this.$props.facilityID) {
      this.property_id = this.$props.facilityID;
    }
    if(this.isOPS){
      this.property_id = this.onboardingData.property.id;
    }
    if(this.defaultProperty) {
      this.property_id = this.defaultProperty ? this.defaultProperty.id : "";
    }
  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
    async validateFacilitySelected() {
      let gate_status = await this.$validator.validate("gate.facility");
      if (!gate_status) {
        this.selectedAccessType = null;
        var collectables = this.errors.items.filter(obj => obj.rule === "unique_external_id");
        this.showMessageNotification({
          type: 'error',
          description: 'There are errors in your form, correct them before continuing.',
          list: this.errors.items[0]
        });
        return;
      }
      this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id))
    },
    updatePageMenu(syncReportFlag) {
      if (syncReportFlag) {
        this.menuItems = ['Access Control Configuration', 'Sync Reports']
      } else {
        this.menuItems = ['Access Control Configuration']
      }
    },
    async fetchGateVendorsList() {
      await api.get(this, api.ACCESS_CONTROL + "vendors").then(r => {
        this.integration_types = r.vendors;
        this.integration_types.map(gate_type => {
          if (gate_type.name === "Derrels") gate_type.name = "DMS";
          return gate_type;
        });
      });
    },
    fetchVendorZonesPlaceholder() {
      const select = "Select ";
      switch (this.selectedAccessType) {
        case "open_tech_cia":
          this.vendor_zones_placeholder = select + "Access Profile";
          break;
        case "spiderdoor":
          this.vendor_zones_placeholder = select + "Keypad Zone";
          break;
        case "rcs":
          this.vendor_zones_placeholder = select + "Keypad Zone";
          break;
        default:
          this.vendor_zones_placeholder = select + "Vendor Zone";
          break;
      }
    },
    async fetchAccessLevels() {
      this.accessLevels = [];
      let r = await api.get(this, api.PROPERTIES + this.property_id + "/access-levels?active=1");
      if (r.length) this.accessLevels = r;
      this.hour_audit.modified_by_fullname = this.accessLevels[0]?.modified_by_fullname;
      this.hour_audit.modified_at = this.accessLevels[0]?.modified_at;
    },
    async fetchAccessInfo() {
      this.fetchInfoInProgress = true;
      try {
        await this.fetchAccessAreas();
        await this.fetchAccessLevels();
        await this.fetchVendorAreas();
        this.fetchInfoInProgress = false;
      } catch {
        this.fetchInfoInProgress = false;
      }

    },
    async fetchVendorAreas() {
      this.zones = [];
      let r = await api.get(this, api.ACCESS_CONTROL + "facilities/" + this.property_id + "/gate-vendor-information?active=1");
      let vendor_zones = r?.property_vendor_information?.vendor_access_areas;
      if (vendor_zones?.length) this.zones = vendor_zones;
    },
    async fetchAccessAreas() {
      this.accessAreas = [];
      let r = await api.get(this, api.PROPERTIES + this.property_id + "/areas?active=1");
      if (r.length) this.accessAreas = r;
      this.area_audit.modified_by_fullname = this.accessAreas[0]?.modified_by_fullname;
      this.area_audit.modified_at = this.accessAreas[0]?.modified_at;
    },
    checkOnboardingAccessHoursStatus() {
      const checkStatus = async () => {
        await this.getOnboardingUserAccessHourStatus();
        if (this.accessHourSyncStatus === 1) {
          console.log("Onboarding is still in progress. Checking again...");
          setTimeout(checkStatus, 5000); // Wait for 5 seconds before checking again
        } else {
          console.log("Onboarding status:", this.accessHourSyncStatus);
          this.onboardUserAccessHourInProgress = false
        }
      };

      checkStatus();
    },
    async onboardUserAccessHour() {
      if (this.selectedAccessType === 'pti' || this.selectedAccessType === 'rcs') {
        this.dialogModal = true;
      } else if (this.selectedAccessType === 'pti_cloud' || this.selectedAccessType === 'open_tech_cia' || this.selectedAccessType === 'doorking' || this.selectedAccessType === 'spiderdoor') {
        this.onboardUserAccessHourInProgress = true;
        this.onboardUserAccessHourStatus = "guidance"
        this.onboardUserAccessHourDescription = "Access Hours onboarding in progress ...."
        await api.put(this, api.PROPERTIES + this.property_id + "/onboard-user-accesslevels").then(r => {
          console.log("ONBOARDING RESPONSE: ", r)
        }).catch(error => {
          console.log("Error: ", error)
          this.onboardUserAccessHourInProgress = false;
          this.onboardUserAccessHourStatus = "error"
          this.onboardUserAccessHourDescription = "An error has occured. Please onboard again."
          this.showMessageNotification({type: 'error', description: error});
        });
        await this.checkOnboardingAccessHoursStatus();
      } else {
        this.showMessageNotification({
          type: 'error',
          description: 'Onboarding feature not available for this vendor at the moment'
        });
      }
    },
    async getOnboardingUserAccessHourStatus() {

      await api.get(this, api.PROPERTIES + this.property_id + "/onboard-user-accesslevels-status").then(r => {
        this.accessHourSyncStatus = r
        console.log("Access Hour Sync Status: ", this.accessHourSyncStatus)
        if (r) {
          if (r === 2) {
            //success
            this.onboardUserAccessHourStatus = "success"
            this.onboardUserAccessHourDescription = "Access hours was onboarded successfully."
          } else if (r === 1) {
            //In Progress
            this.onboardUserAccessHourInProgress = true;
            this.onboardUserAccessHourStatus = "guidance"
            this.onboardUserAccessHourDescription = "Access Hours onboarding in progress ...."
          } else {
            //ERROR
            this.onboardUserAccessHourStatus = "error"
            this.onboardUserAccessHourDescription = "An error has occured. Please onboard again."
          }
        }
      }).catch(error => {
        console.log("Error Getting onboarding User Access Hour Status: ", error)
        //this.showMessageNotification({ type: 'error', description: 'Unable to get onboarding status'});
      });
    },
    compareVersions(currentVersion, latestVersion) {
      if (currentVersion === null) return -1;

      const currentComponents = currentVersion.split('.').map(Number);
      const latestComponents = latestVersion.split('.').map(Number);

      for (let i = 0; i < Math.max(currentComponents.length, latestComponents.length); i++) {
        const current = currentComponents[i] || 0;
        const latest = latestComponents[i] || 0;

        if (current < latest) {
          return -1; // Current version is older
        } else if (current > latest) {
          return 1; // Current version is newer
        }
      }

      return 0; // Versions are equal
    },
    fetchData() {
      if (!this.property_id) {
        return;
      } else {
        api.get(this, api.PROPERTIES + this.property_id + "/access").then(r => {
          if (r.access.Credentials) {
            this.selectedGateAccessId = r.access.access_id;
            this.access = Object.assign({}, r.access);
          }
          if (r.agentInfo) {
            this.current_version = r?.agentInfo?.current_version;
            this.latest_version = r?.agentInfo?.latest_version;
            this.install_code = r?.agentInfo?.install_code;
            this.agent_type_id = r?.agentInfo?.agent_type_id;
            this.gate_facility_id = r?.agentInfo?.gate_facility_id;
            this.hasNewVersion = r?.agentInfo?.hasNewVersion;
            if (!this.install_code && this.latest_version && this.hasNewVersion) {
              api.post(this, api.ACCESS_CONTROL + "properties/" + this.property_id + "/agent/installcode?gate_facility_id=" + this.gate_facility_id + "&agent_type_id=" + this.agent_type_id).then(r => {
                this.install_code = r.install_code;
              });
            }
            if (this.latest_version && this.hasNewVersion) {
              this.isUpdateAvailable = true;
            } else {
              this.isUpdateAvailable = false;
            }
          }

          this.$emit('setAccessType', this.selectedGateAccessId);
        });
        this.fetchAccessInfo();
      }


    },
    async save(access) {
      const data = {
        access: access,
        property_id: this.property_id,
        access_id: this.selectedGateAccessId
      };
      let r = await api.post(this, api.ACCESS_CONTROL, data);
      this.fetchData(true);
    },
    async removeAccess() {

      this.removeInProgress = true;

      try {

        let r = await api.delete(
            this,
            api.PROPERTIES + this.property_id + "/access",
            null,
            "accessRemove"
        );
        this.cancel();
        this.showMessageNotification({
          type: 'success',
          description: 'You have successfully removed the access control integration.'
        })

      } catch (err) {
        this.showMessageNotification({description: err.toString()});
      }
      this.resetGateUpdateInfo();
      this.confirmRemoveModal = false;
      this.removeInProgress = false;
    },
    openDefaultScheduleModal(item) {
      this.selectedDefaultScheduleItem = item;
      this.defaultScheduleModal = true;
    },

    async downloadAgentUpdate(install_code) {
      this.downloadAgentInProgress = true;

    }
  }
};
</script>

<style scoped>

</style>
