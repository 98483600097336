<template>
    <div class="section-content pt-5">

        <span :class="{'mt-0' : !snackbarClosed}">
            <cms-snackbar v-if="!isHBUser" @close="snackbarClosed = true"></cms-snackbar>
            <!-- <snackbar v-else @close="snackbarClosed = true"></snackbar>  -->
            <quick-actions-snackbar v-else-if="!$vuetify.breakpoint.xs" @close="snackbarClosed = true"></quick-actions-snackbar>
        </span>


        <template v-if="isHBUser">
            <hb-header
                :class="{'pt-5' : snackbarClosed}"
                fewer-actions
            >
                <template v-slot:left>
                    <hb-page-header :class="{'hb-dashboard-page-header': $vuetify.breakpoint.xs}"
                        :title="menuItems[getSelectedMenuItem] ? menuItems[getSelectedMenuItem].title : properties.length == 1 ? properties[0].name + ' Dashboard' : 'Property Group Dashboard'"
                    >
                    </hb-page-header>
                </template>
                <!-- PROG-2678 - Commnet out this code to allow static dashboards only -->
                <!-- <template v-slot:right>
                    <div class="ml-2">
                        <hb-btn class="ml-1" icon tooltip="Edit Dashboard" :active="showAside" @click="toggleSideBar" v-if="selection === 'dashboard'">mdi-pencil</hb-btn>
                        <hb-btn class="ml-1" v-if="properties.length == 1 && hasPermission('access_settings')" icon tooltip="Sidebar" :active="showNavigation" @click="toggleNavigation">mdi-table-actions-custom-1</hb-btn>
                    </div>
                </template> -->
            </hb-header>
        </template>

        <div class="primary-section-content">
            <template v-if="isHBUser">
                <component :key="report_key" v-if="menuItems[getSelectedMenuItem]" v-bind:is="menuItems[getSelectedMenuItem].key" :facilityID="properties[0].id"></component>

                <grid-layout

                    v-else-if="layout.length > 0 && hasPermission('view_dashboard')"
                    :layout.sync="layout"
                    :row-height="150"
                    :is-draggable="showAside"
                    :is-resizable="false"
                    :is-mirrored="false"
                    :vertical-compact="true"
                    :margin="[16, 16]"
                    :use-css-transforms="true"
                    @layout-updated="layoutUpdatedEvent"
                    :col-num="$vuetify.breakpoint.width < 767 ? 1 : 6"
                    ref="grid_layout"
                    class="dashboard-grid-layout"
                >
                    <grid-item
                    v-for="(d, i) in layout"
                    :x="d.x"
                    :y="d.y"
                    :w="d.w"
                    :h="d.h"
                    :i="d.i"
                    :maxW="d.maxW"
                    :maxH="d.maxH"
                    :minW="d.minW"
                    :minH="d.minH"
                    :key="i"
                    class="pt-0 pr-0"
                    >
                        <Widget
                            :key="i"
                            :canDelete="showAside"
                            :payload.sync="d"
                            :property="property"
                            :data-id="d.id"
                            :data-name="d.name"
                            :dashboard="d"
                            :property_id="property.id"
                            class="ti-widget"
                            @resize="layoutUpdatedEvent(layout)"
                        />
                    </grid-item>
                </grid-layout>
                <div v-else class="content-block-dashboard" v-show="!dashes.length || !hasPermission('view_dashboard')" >
                    <div class="content-block-data-blank">
                        <div class="dashbaord-explainer-text">New Dashboard Item</div>
                    </div>
                </div>
                <!-- PROG-2678 - Commnet out this code to allow static dashboards only -->
                <!-- <v-navigation-drawer
                    right
                    hide-overlay
                    clipped
                    class="ma-0 pa-0 py-3 pl-1 attached-drawer hb-tertiary-navigation"
                    :style="{ height: 'auto', flex: showAside ? '0 1 272px':'0 1 0' }"
                    color="grey lighten-5"
                    disable-resize-watcher
                    mobile-breakpoint="1"
                    flat
                    v-model="showAside"
                >
                    <div class="mt-3 ml-5 mr-0">
                        <div class="aside-header">
                            <div class="aside-summary-row vertical">
                                <div class="hb-larger-font-size font-weight-medium">Edit Dashboard Panels</div>
                                <p class="hb-text-light mt-1 mb-2">
                                    Drag and drop panels onto your dashboard
                                </p>
                            </div>
                        </div>
                        <draggable v-show="showAside" filter=".no-drag" group="droptarget" @start="drag=true" @end="endDrag" class="dashboard-options">
                            <v-card v-for="o in dashboard_types" :key="o.id" :data-name="o.id" class="mx-2 my-3 py-2 pl-4 pr-2 hb-elevation-medium" :class="{'no-drag': isDisabled(o)}">
                                <span class="font-weight-medium">{{o.name}}</span>
                                <br />
                                <span class="hb-text-light">{{o.description}}</span>
                            </v-card>
                        </draggable>
                    </div>
                </v-navigation-drawer> -->
                <v-navigation-drawer
                    v-model="showNavigation"
                    :right="!$vuetify.breakpoint.xs"
                    :hide-overlay="$vuetify.breakpoint.smAndUp"
                    :clipped="!$vuetify.breakpoint.xs"
                    :class="{'attached-drawer': !$vuetify.breakpoint.xs}"
                    class="ma-0 px-0 py-1 hb-tertiary-navigation property-dashboard-sidebar-wrapper"
                    :style="{ height: 'auto', flex: showNavigation ? '0 1 272px':'0 1 0' }"
                    color="grey lighten-5"
                    disable-resize-watcher
                    mobile-breakpoint="600"
                    :bottom="$vuetify.breakpoint.xs"
                    :app="$vuetify.breakpoint.xs"
                    flat
                >
                    <v-list dense style="width:100%;">
                        <v-list-item-group v-model="selection">
                            <v-list-item to="/dashboard" value="dashboard" exact :class="{'font-weight-medium' : selection === 'dashboard'}" class="pl-5">
                                Property Dashboard
                            </v-list-item>
                            <v-list-item v-for="(item, i) in menuItems" exact :value="item" :to="item.path" :class="{'font-weight-medium' : selection == item}" :key="'menuItem' + i" class="pl-5">
                                {{item.title}}
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>
            </template>
            <div class="upsell-cards-wrap" v-else>
                <template  v-if="!upsellData.dashboard">
                    <UpsellSkeletonLoader></UpsellSkeletonLoader>
                    <div class="d-flex">
                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                    </div>
                </template>
                <template v-else >
                    <UpsellCardsContainer
                        :data="upsellData.dashboard"
                        @showDemoModal="showDemoModal"
                    ></UpsellCardsContainer>
                </template>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import api from '../../assets/api.js';
    import ActionItems from './ActionItems.vue';
    import MaintenanceRequests from './MaintenanceRequests.vue';
    import MonthlyRevenue from './MonthlyRevenue.vue';
    import AvgLeaseLength from './AvgLeaseLength.vue';
    import NewLeads from './NewLeads.vue';
    import Units from './Units.vue';
    import Aging from './Aging.vue';
    import Overlock from './Overlock.vue';
    import Vacancies from './Vacancies.vue';
    import VacancyByCategory from './VacancyByCategory.vue';
    import Autocomplete from '../assets/Autocomplete.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import TertiaryNavigation from '../assets/TertiaryNavigation.vue';
    import Sortable from "sortablejs";
    import Vue from 'vue';
    import DynamicForm from '../DynamicForm';
    import Basic from './widgets/Basic.vue';
    import Advanced from './widgets/Advanced.vue';
    import Widget from './widgets/Widget.vue';
    import { mapGetters, mapActions } from 'vuex';
    import draggable from 'vuedraggable';
    import Header from '../header/Index.vue';
    import VueGridLayout from 'vue-grid-layout';
    // import Snackbar from '../snackbar/Index.vue';
    import QuickActionsSnackbar from '../snackbar/QuickActionsSnackbar.vue';
    import CmsSnackbar from '../snackbar/CmsSnackbar.vue';
    import AccessControl from '../settings/AccessControl/Index.vue';
    import PaymentConfiguration from '../settings/PaymentConfiguration/index.vue';
    import Products from '../settings/Products.vue';
    import LeaseConfiguration from '../settings/PropertyTemplates/Index.vue';
    import Rent from '../settings/Rent/Index.vue'
    import Fees from '../settings/Fees/Index.vue'
    import Apps from './Apps.vue'
    import UpsellSkeletonLoader from './widgets/components/UpsellSkeletonLoader.vue'
    import UpsellCardsContainer from './widgets/components/UpsellCardsContainer.vue'
    // import PhoneNumberInput from './widgets/components/PhoneNumberInput.vue'
    import { EventBus } from "@/EventBus.js";

    export default {
        data() {
            return {
                snackbarClosed: false,
                breakpoints: {
                    lg: 1366,
                    md: 800,
                    sm: 768,
                    xs: 480,
                    xxs: 0
                },
                cols: {
                    lg: 9,
                    md: 6,
                    sm: 3,
                    xs: 3,
                    xxs: 3
                },
                buttonGroup: null,
                property_id: '',
                showAside: false,
                showNavigation: false,
                navigationHeading: "Property Dashboard",
                menuItems: [
                    // {path: "/dashboard/overview", key: "overview", title: "Overview"},
                    // {path: "/dashboard/configure-lease", key: "configure-lease", title: "Configure Lease"},
                    // {path: "/dashboard/maintenance", key: "maintenance", title: "Maintenance"},
                    {path: "/dashboard/payments", key: "payment-configuration", title: "Payment Processing"},
                    // {path: "/dashboard/applications", key: "applications", title: "Applications"},
                    // {path: "/dashboard/merchandise", key: "merchandise", title: "Merchandise"},
                    // {path: "/dashboard/property-images", key: "property-images", title: "Property Images"},
                    {path: "/dashboard/access-control", key: "access-control", title: "Access Control"},
                    {path: "/dashboard/products", key: "products", title: "Products"},
                    {path: "/dashboard/lease-configuration", key: "lease-configuration", title: "Lease Configuration"},
                    {path: "/dashboard/fees", key: "fees", title: "Fees"},
                    { path: "/dashboard/rent", key: "rent", title: "Rent"}

                    // {path: "/dashboard/settings", key: "settings", title: "Settings"}

                ],
                propertySearchField: null,
                droppable: false,
                palo:false,
                maintain: false,
                sortable: null,
                receiver: null,
                isMounted: false,
                layout:[],
                selection: 'dashboard',
                demoModal: false,
                facilityQueryParams: "",
                hasTi: false,
                hasDs: false,
                upsellDataCopy: {},
                report_key: 0
            }
        },
        async mounted(){
            this.$on('HBGet:Dashboards', this.fetchDashboards);
            this.isMounted = true;
        },
        destroyed(){
            this.$off('HBGet:Dashboards', this.fetchDashboards);
            EventBus.$off('logout', this.logout);
            EventBus.$off('refetch_data', () => this.fetchData());
        },
        created(){
            //this.fetchDashboardTypes();
            this.fetchDashboards();
            this.setLayout();
            this.createSnackbar();

            let extensions = this.$store.getters['authenticationStore/getExtensionPoints'].facility;
            if (extensions)
                extensions.map(e => {
                    this.menuItems.push({
                        path: "/dashboard/app/" + e.id,
                        key: 'apps',
                        title: e.name
                    });
                })

            EventBus.$on('logout', this.logout);
            EventBus.$on('refetch_data', () => this.fetchData());
        },
        components:{
            ActionItems,
            MaintenanceRequests,
            MonthlyRevenue,
            Units,
            NewLeads,
            Aging,
            Overlock,
            Autocomplete,
            Vacancies,
            VacancyByCategory,
            AvgLeaseLength,
            Loader,
            TertiaryNavigation,
            DynamicForm,
            Basic,
            Advanced,
            Widget,
            draggable,
            Header,
            // Snackbar,
            QuickActionsSnackbar,
            CmsSnackbar,
            GridLayout: VueGridLayout.GridLayout,
            GridItem: VueGridLayout.GridItem,
            AccessControl,
            PaymentConfiguration,
            Products,
            LeaseConfiguration,
            Rent,
            Fees,
            Apps,
            UpsellSkeletonLoader,
            UpsellCardsContainer,
            // PhoneNumberInput
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'propertiesStore/property',
                dashes: 'dashboardsStore/dashboards',
                dashboard_types: 'dashboardsStore/dashboard_types',
                hasPermission: 'authenticationStore/rolePermission',
                glossaryAppId: 'authenticationStore/getGlossaryAppId',
                isHBUser: 'authenticationStore/isHBUser',
                isUpsellLoading: 'dashboardsStore/is_upsell_loading',
                ownerProperties: 'dashboardsStore/owner_properties',
                upsellData: 'dashboardsStore/get_upsell_data',
                ownerFacilities: 'dashboardsStore/owner_properties'
            }),
            layoutFromStore:{
                get(){
                    return this.dashes;
                },
                // set(newLayout) {
                //     this.$store.dispatch('dashboardsStore/setLayout', newLayout);
                // }
            },
            columns(){
                if(!this.isMounted) return 6;
                return this.$refs.widgetContainer.clientWidth > 1366 ? 9 : 6;
            },
            getSelectedMenuItem() {
                return this.menuItems.findIndex(item => item?.path === this.$route.path );
            }
        },
        watch: {
            layoutFromStore(val) {
                if (val) {
                    this.setLayout();
                }
            },
            properties(){
                this.createSnackbar()
                if(this.properties.length > 1){
                    this.showNavigation = false;
                }
                if(this.properties.length === 1 && this.hasPermission('access_settings') && !$vuetify.breakpoint.xs) {
                    this.showNavigation = true;
                }
            },
            glossaryAppId: {
                immediate: true,
                handler() {
                    this.fetchUpsellData()
                }
            },
            // isHBUser() {
            //     this.fetchUpsellData(this.facilityQueryParams)
            // },
            // ownerFacilities: {
            //     handler(value) {
            //         this.showTiDsCard(value);
            //     },
            //     deep: true

            // }
        },
        methods: {
            ...mapActions({
                addWidget: 'dashboardsStore/addWidget',
                snackbarSetLabel: 'snackbarStore/setLabel',
                snackbarSetActions: 'snackbarStore/setActions',
                snackbarSetValue: 'snackbarStore/setValue',
                fetchUpsellData: 'dashboardsStore/fetchUpsellData',
            }),
            setLayout() {
                let layoutStore = [...this.layoutFromStore];
                if (this.$vuetify.breakpoint.xs && layoutStore.length === 3) {
                    this.layout = layoutStore.slice().sort((a, b) => a.x - b.x);
                } else if (!this.$vuetify.breakpoint.xs) {
                    this.layout = layoutStore;
                }
            },
            layoutUpdatedEvent: function(newLayout){
                this.$refs.grid_layout.resizeEvent()
                //this.$store.dispatch('dashboardsStore/setLayout', newLayout);
            },
            isDisabled(widget){
                return this.dashes.find(d => d.dashboard_type_id === widget.id);
            },
            toggleSideBar(){
                this.showNavigation = false;
                this.showAside = !this.showAside;
            },
            toggleNavigation(){
                this.showAside = false;
                this.showNavigation = !this.showNavigation;
            },
            async endDrag(drag){

                var saved = {
                    dashboard_type_id: drag.item.getAttribute('data-name'),
                    sort:0
                };
                await this.addWidget({context:this, dashboards:[saved]});
                await this.fetchDashboards();
            },
            deleteDashboard(d){
                api.delete(this, api.DASHBOARD ,  d.id).then(r => {
                    this.fetchDashboards();
                })
            },
            // fetchDashboardTypes(){
            //     this.$store.dispatch('dashboardsStore/getDashboardTypes');
            // },
            fetchDashboards(){
                this.$store.dispatch('dashboardsStore/getDashboards', { pid: this.property_id });
            },
            createSnackbar(){
                let actions = []
                this.snackbarSetLabel('Quick Actions');
                if(this.isHBUser)
                    actions = [
                        {
                            command: 'HB-Navigation:QuickLinks',
                            title: 'Move In/Reserve',
                            payload: 'movein'
                        },
                        {
                            command: 'HB-Navigation:QuickLinks',
                            title: 'Take a Payment',
                            payload: 'payment'
                        },
                        {
                            command: 'sell_merchandise',
                            title: 'Sell Merchandise',
                            payload: 'merchandise'
                        }
                    ]
                else
                    actions = [
                        {
                            command: 'HB-Navigation:showSettingsModal',
                            title: 'Launch CMS',
                            payload: 'cms'
                        },
                        {
                            command: 'logout',
                            title: 'Logout',
                            payload: ''
                        }
                    ]
                this.snackbarSetActions(actions);

                this.snackbarSetValue(true);
            },
            showDemoModal(type) {
                //Redirect to TenantInc 'Schedule a Demo' form
                window.open(
                    'https://www.tenantinc.com/#Get-Access',
                    '_blank'
                );
                // this.demoModal = true
            },
            logout() {
                this.$router.push('/logout')
            },
            fetchData(){
                this.report_key++;
            }
        }
    }
</script>
<style scoped lang="scss">
.primary-section-content {
    display: flex;
    height: calc(100% - 70px);
    flex: 1 1 0;
    .v-navigation-drawer--open {
            flex: 1 1 auto;
    }
    .upsell-cards-wrap {
        width: 100%;
    }
}
.content-block-dashboard{
    flex: 0 100%;
}
    .attached-drawer {
        height: auto;
        padding: 0;
        width:0;

        &.theme--light{
            &.v-navigation-drawer{
                background-color:#fafafa;
            }
        }
        &.attached-drawer--active {
            width: 400px;
        }
        .no-drag {
            background: rgba(0,0,0,0.1);
        }
    }

    .property-dashboard-sidebar-wrapper .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
        background-color: #f9fafb !important;
        border-left: 4px solid #f9fafb;
    }

    .property-dashboard-sidebar-wrapper .v-navigation-drawer__content a.v-list-item--active {
        background-color: #e2f2f3 !important;
        border-left: 4px solid #00848E;
    }

    .tile-section {
        width: 100%;
        display: block;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding: 10px 10px 20px 10px;
    }

    .dashboard-options {
        cursor: grab;
        .no-drag {
            cursor: not-allowed;
        }
    }

    .vue-grid-layout.dashboard-grid-layout {
        margin-left: -15px;
        margin-right: -15px;
        width: 103%;
    }

    @media (max-width: 767px) {
        .edit-dashboard-btn{
            display:none;
        }
        .section-content ::v-deep .hb-dashboard-page-header .hb-header-font-size {
            padding-left: 0px !important;
        }
        .dashboard-grid-layout .vue-grid-item  {
            position: relative !important;
            margin-bottom: 16px !important;
        }
        .vue-grid-layout.dashboard-grid-layout {
            margin-bottom: 16px;
            margin-left: -5px;
            height: auto !important;
        }
    }
.vue-grid-layout{
    width:100%;
}


</style>

<style>
.hb-dashboard-page-header .hb-header-font-size {
    font-size: 16px;
    padding-left: 20px !important;
}
</style>